import DropdownNavbar from "../DropdownNavbar";
import { NavbarLink, Pages } from "@/types/navbar";
import { SiteNavbarRouteLinks } from "@config/navbarItems";
import { usePages } from "@hooks/usePages";
import { apiFetch } from "@services/api";
import { Navbar } from "flowbite-react";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

export const SiteNavLinkTabs = () => {
  const { pathname, search } = useLocation();
  const isHomePage = pathname === "/home";
  const [tabsLastLocation, setTabsLastLocation] = useState(
    new Map(SiteNavbarRouteLinks.map((item) => [item.route, item.route]))
  );

  const handleClick = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: NavbarLink
  ) => {
    if (item.isExternalSite) {
      event.preventDefault();
      if (item.canAccess) {
        try {
          if (item.key === Pages.docs) {
            const newTap = window.open("", "_blank");
            const data = await apiFetch("/v1/gitbook");
            const url = data?.docs_url || item.route;
            if (newTap) {
              newTap.location.href = url;
            } else {
              console.error("Failed to open new tab");
            }
          } else {
            window.open(item.route, "_blank");
          }
        } catch (error) {
          console.error("Failed to fetch API documentation URL", error);
        }
      }
    }
  };

  useEffect(() => {
    const tabRoute = pathname.split("/")[2];
    const lastLocation = `${pathname}${search}`;
    setTabsLastLocation((prev) => {
      const newTabs = new Map(prev);
      newTabs.set(tabRoute, lastLocation);
      return newTabs;
    });
  }, [pathname, search]);

  const { navbarItems } = usePages();

  return (
    <Navbar.Collapse className="flex flex-row gap-8">
      <ul className="flex flex-row md:space-x-8">
        {navbarItems.map((item) =>
          item.type === "dropdown" ? (
            <DropdownNavbar
              key={item.title}
              title={item.title}
              handleClick={handleClick}
              items={item.items.map((navItem) => ({
                ...navItem,
                name: navItem.title,
                description: navItem.description ?? "",
                href: tabsLastLocation.get(navItem.route) ?? navItem.route,
                icon: navItem.icon as React.ComponentType<
                  React.SVGProps<SVGSVGElement>
                >,
              }))}
            />
          ) : (
            <NavLink
              className={`inline-flex items-center px-2 py-1 text-base font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 transition-colors duration-200 ${
                pathname.startsWith(item.route)
                  ? "font-semibold text-black dark:text-white"
                  : isHomePage
                  ? "text-black dark:text-white"
                  : "text-ca-gray-light dark:text-ca-text-100 hover:text-black dark:hover:text-white"
              }`}
              key={item.title}
              onClick={(e) => handleClick(e, item)}
              to={tabsLastLocation.get(item.route) ?? item.route}
              aria-current={pathname.startsWith(item.route) ? "page" : undefined}
            >
              <div className="flex gap-1 items-center">
                {item.title}
              </div>
            </NavLink>
          )
        )}
        {/* <button
          className="p-1 hover:bg-gray-200 rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
          aria-label="Search"
        >
          <FiSearch className="h-5 w-5 text-gray-600 cursor-pointer dark:text-white" />
        </button> */}
      </ul>
    </Navbar.Collapse>
  );
};
